import { Box, Button, Spacing, Txt } from '@florencecard/components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@florencecard-shared/colors';
import Router from 'next/router';
import React from 'react';
import { openKakaoTalkInquiryChat } from '~/utils';

const hrefToFindLink = () => {
  Router.push('/find-link');
};

export default function IntroSection8() {
  return (
    <Box paddingBottom={24}>
      <SectionInner>
        <Item>
          <Txt as="div" fontSize={[20, 22]} color={colors.grayBold}>
            청첩장 링크를 잊어버리셨나요?
          </Txt>
          <Spacing size={16} />
          <Button
            size={40}
            paddingLeft={52}
            paddingRight={52}
            borderRadius={23.5}
            color={colors.primary}
            border={`1px solid ${colors.primary}`}
            onClick={hrefToFindLink}
            css={css`
              &:hover {
                background-color: ${colors.primary};
                color: ${colors.white};
              }
            `}
          >
            <Txt fontSize={14}>링크 찾기</Txt>
          </Button>
        </Item>
        <Item>
          <Txt as="div" fontSize={[20, 22]} color={colors.grayBold}>
            궁금하신 점이 있으신가요?
          </Txt>
          <Spacing size={16} />
          <Button
            size={40}
            border={`1px solid ${colors.primary}`}
            borderRadius={23.5}
            onClick={openKakaoTalkInquiryChat}
            paddingLeft={52}
            paddingRight={52}
            color={colors.primary}
            css={css`
              &:hover {
                background-color: ${colors.primary};
                color: ${colors.white};
              }
            `}
          >
            <Txt fontSize={14}>1:1 문의하기</Txt>
          </Button>
        </Item>
      </SectionInner>
    </Box>
  );
}

const SectionInner = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex: 1 1 50%;
  display: block;
  padding: 76px 0 75px 10%;

  @media screen and (max-width: 720px) {
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;

    &:first-of-type {
      border-bottom: 1px solid ${colors.border};
    }
  }
`;
