import { Box, Button, Flex, Icon, Spacing, Txt } from '@florencecard/components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Carousel, CarouselSlide } from '@florencecard-components/carousel';
import { safeScrollTo } from '@florencecard-lib/scroll';
import { colors } from '@florencecard-shared/colors';
import Router from 'next/router';
import React from 'react';
import { letterThemes } from '~/models';
import { onMobile } from '~/ui/styles';
import { SectionInner } from './styled';

const hrefCreateLetterPage = () => {
  Router.push('/create');
};

const hrefStorePage = () => {
  Router.push('/store-list');
  safeScrollTo(window, { top: 0 });
};

export default function IntroSection1() {
  return (
    <Section>
      <SectionInner>
        <Flex
          flexDirection="column"
          alignItems={['center', 'flex-start']}
          order={[2, 1]}
          marginTop={[25, 0]}
        >
          <Txt
            as="h1"
            fontSize={[18, 36]}
            fontWeight="bold"
            color={colors.black}
            lineHeight={['30px', '52px']}
            textAlign={['center', 'left']}
          >
            쉽고 예쁜 <Box as="br" display={['none', 'block']} />
            모바일 청첩장 만들기
            <br />
            플로렌스카드
          </Txt>
          <Spacing size={[16, 48]} />
          <Button
            size={[48, 48]}
            paddingLeft={[40, 74]}
            paddingRight={[40, 74]}
            onClick={hrefCreateLetterPage}
            borderRadius={23.5}
            backgroundColor={colors.primary}
            fontSize={[14, 16]}
            color={colors.white}
            css={css`
              &:hover {
                background-color: ${colors.primary};
              }
            `}
          >
            청첩장 만들기
          </Button>
          <Box display={['block', 'none']}>
            <Spacing size={10} />
            <Button
              size={40}
              fontSize={14}
              fontWeight="regular"
              color={colors.primary}
              onClick={hrefStorePage}
            >
              <Flex.Center>
                &nbsp;&nbsp;모바일 청첩장
                <Icon
                  name="back"
                  color={colors.primary}
                  size={16}
                  marginTop={2}
                  marginLeft={2}
                  css={{
                    transform: 'rotate(180deg)',
                  }}
                />
              </Flex.Center>
            </Button>
          </Box>
        </Flex>
        <Box order={[1, 2]}>
          <Showcase>
            <ShowcaseFrame
              src="https://assets.florencecard.me/images/phoneframe@3x.png"
              srcSet={`https://assets.florencecard.me/images/phoneframe.png 1x, https://assets.florencecard.me/images/phoneframe@2x.png 2x, https://assets.florencecard.me/images/phoneframe@3x.png 3x`}
            />
            <ShowcaseContent>
              <Carousel slidesPerView={1} autoplay={true}>
                {letterThemes.map(({ id, name, previewImage }) => (
                  <Slide key={id}>
                    <img
                      alt={`${name} 테마 템플릿 예시`}
                      src={previewImage.src}
                      srcSet={previewImage.srcset}
                    />
                  </Slide>
                ))}
              </Carousel>
            </ShowcaseContent>
          </Showcase>
        </Box>
      </SectionInner>
    </Section>
  );
}

const Section = styled.section`
  padding: 75px 0;

  ${onMobile} {
    padding: 24px 0 56px 0;
  }
`;

const Showcase = styled.div`
  position: relative;
  width: 340px;
  height: 706px;
  display: block;
  padding: 4px 6px;

  ${onMobile} {
    width: 140px;
    height: 290px;
    padding: 2px;
  }
`;

const ShowcaseFrame = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
`;

const ShowcaseContent = styled.div`
  overflow: hidden;
`;

const Slide = styled(CarouselSlide)`
  width: 100%;

  > img {
    display: block;
    width: 100%;
  }
`;
