import React from 'react';
import Footer from '~/components/Footer';
import IntroSection1 from '~/components/IntroSection/IntroSection1';
import IntroSection2 from '~/components/IntroSection/IntroSection2';
import IntroSection3 from '~/components/IntroSection/IntroSection3';
import IntroSection4 from '~/components/IntroSection/IntroSection4';
import IntroSection5 from '~/components/IntroSection/IntroSection5';
import IntroSection6 from '~/components/IntroSection/IntroSection6';
import IntroSection7 from '~/components/IntroSection/IntroSection7';
import IntroSection8 from '~/components/IntroSection/IntroSection8';

export default function IntroPage() {
  return (
    <>
      <main>
        <IntroSection1 />
        <IntroSection2 />
        <IntroSection3 />
        <IntroSection4 />
        <IntroSection5 />
        <IntroSection6 />
        <IntroSection7 />
        <IntroSection8 />
      </main>
      <Footer />
    </>
  );
}
