import { Box, Button, Spacing, padding, Txt } from '@florencecard/components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@florencecard-shared/colors';
import Router from 'next/router';
import React from 'react';
import { onMobile } from '~/ui/styles';

const hrefToLetterCreate = () => {
  Router.push('/create');
};

export default function IntroSection7() {
  return (
    <Section>
      <Txt
        as="h2"
        fontSize={[18, 24]}
        lineHeight="30px"
        color={colors.white}
        textAlign={['center', 'left']}
      >
        쉽고 예쁜 모바일 청첩장<Box display={['none', 'inline']}>, 플로렌스카드</Box>
      </Txt>
      <Spacing size={16} />
      <Txt
        as="p"
        fontSize={[14, 18]}
        fontWeight="light"
        lineHeight={['22px', '33px']}
        color={colors.white}
        textAlign="center"
      >
        모바일 청첩장이 고민이신가요?
        <br />
        다양한 테마를 한 번의 결제로 이용해 보세요.
      </Txt>
      <Spacing size={[24, 57]} />
      <Button
        size={[40, 48]}
        border={`1px solid ${colors.white}`}
        borderRadius={23.5}
        onClick={hrefToLetterCreate}
        paddingLeft={[52, 74]}
        paddingRight={[52, 74]}
        color={colors.white}
        css={css`
          &:hover {
            background-color: ${colors.white};
            color: ${colors.primary};
          }
        `}
      >
        <Txt fontSize={[14, 16]}>청첩장 만들기</Txt>
      </Button>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${padding({
    y: 90,
  })};

  background-color: ${colors.primary};

  ${onMobile} {
    ${padding({
      y: 56,
    })};
  }
`;
