import styled from '@emotion/styled';
import { onMobile } from '~/ui/styles';

export const SectionInner = styled.div`
  max-width: 1180px;
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${onMobile} {
    flex-direction: column;
    padding: 0 20px;
  }
`;
