import { Flex, Spacing, Txt } from '@florencecard/components';
import styled from '@emotion/styled';
import { colors } from '@florencecard-shared/colors';
import React from 'react';
import { onMobile } from '~/ui/styles';
import { SectionInner } from './styled';

export default function IntroSection6() {
  return (
    <Section>
      <Inner>
        <Left>
          <Flex.Center>
            <img
              alt=""
              height={42}
              src="https://assets.florencecard.me/images/ic-call-main@3x.png"
              srcSet={`https://assets.florencecard.me/images/ic-call-main.png 1x, https://assets.florencecard.me/images/ic-call-main@2x.png 2x, https://assets.florencecard.me/images/ic-call-main@3x.png 3x`}
            />
          </Flex.Center>
          <Spacing size={16} />
          <Txt
            as="h2"
            fontSize={[18, 24]}
            fontWeight="bold"
            lineHeight="30px"
            color={colors.black}
            textAlign={['center', 'left']}
          >
            전화하고 지도 찾고
          </Txt>
          <Spacing size={16} />
          <Txt
            as="p"
            fontSize={[14, 18]}
            lineHeight={['22px', '33px']}
            fontWeight="light"
            color="#343a40"
            textAlign={['center', 'left']}
          >
            신랑과 신부에게 전화나 메시지를 보내보세요.
            <br />
            예식장까지 바로 네비게이션 사용이 가능해요.
          </Txt>
        </Left>
        <Right>
          <Img
            src="https://assets.florencecard.me/images/img-screen-map@3x.png"
            srcSet={`https://assets.florencecard.me/images/img-screen-map.png 1x, https://assets.florencecard.me/images/img-screen-map@2x.png 2x, https://assets.florencecard.me/images/img-screen-map@3x.png 3x`}
            alt=""
          />
        </Right>
      </Inner>
    </Section>
  );
}

const Section = styled.section`
  height: 560px;
  background-color: #f8f9fa;

  ${onMobile} {
    padding-top: 58px;
  }
`;

const Inner = styled(SectionInner)`
  height: 100%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${onMobile} {
    flex: none;
    align-items: center;
  }
`;

const Right = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 340px;

  ${onMobile} {
    flex: 1 1 auto;
    width: 240px;
    height: auto;
  }
`;

const Img = styled.img`
  position: absolute;
  left: 0;
  top: 108px;
  width: 340px;

  ${onMobile} {
    top: 72px;
    width: 240px;
  }
`;
