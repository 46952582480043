import { Flex, Spacing, padding, Txt } from '@florencecard/components';
import { colors } from '@florencecard-shared/colors';
import React from 'react';
import { useMediaQuery } from '~/core/hooks';
import { onMobileMediaQuery } from '~/ui/styles';

export default function IntroSection2() {
  const isMobile = useMediaQuery(onMobileMediaQuery);

  return (
    <Flex.Center
      as="section"
      flexDirection="column"
      paddingTop={[56, 60]}
      paddingBottom={[56, 97]}
      css={[
        padding({
          top: 60,
          bottom: 97,
        }),
      ]}
      backgroundColor="#f8f9fa"
    >
      <Flex.Center>
        <img
          alt=""
          src="https://assets.florencecard.me/images/ic-invitation-main@3x.png"
          srcSet={`https://assets.florencecard.me/images/ic-invitation-main.png 1x, https://assets.florencecard.me/images/ic-invitation-main@2x.png 2x, https://assets.florencecard.me/images/ic-invitation-main@3x.png 3x`}
          width={isMobile ? 72 : 100}
          height={isMobile ? 72 : 100}
        />
      </Flex.Center>
      <Spacing size={isMobile ? 16 : 32} />
      <Txt
        as="h2"
        fontSize={[18, 24]}
        fontWeight="bold"
        lineHeight="30px"
        color={colors.black}
        textAlign="center"
      >
        종이 청첩장은 마음에 드는데,{isMobile ? <br /> : ' '}모바일 청첩장은?
      </Txt>
      <Spacing size={isMobile ? 16 : 14} />
      <Txt
        as="p"
        fontSize={[14, 18]}
        fontWeight="light"
        lineHeight={['22px', '33px']}
        color="#343a40"
        textAlign="center"
        css={{
          padding: '0 24px',
          wordBreak: 'keep-all',
        }}
      >
        모바일 청첩장이 고민이신가요?
        <br />
        종이 청첩장을 주문할 필요 없이 바로 만들어 보세요.
      </Txt>
    </Flex.Center>
  );
}
