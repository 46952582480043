import { Spacing, Txt } from '@florencecard/components';
import styled from '@emotion/styled';
import { colors } from '@florencecard-shared/colors';
import React, { useMemo } from 'react';
import { useLetterPrice } from '~/hooks';
import { onMobile } from '~/ui/styles';
import { addDelimiter } from '~/utils';
import { SectionInner } from './styled';

export default function IntroSection4() {
  const price = useLetterPrice(true);
  const displayPrice = useMemo(() => (price != null ? addDelimiter(price) : '-'), [price]);

  return (
    <Section>
      <SectionInner>
        <Left>
          <Txt
            as="h2"
            fontSize={[20, 24]}
            fontWeight="bold"
            lineHeight={['30px', '36px']}
            color={colors.black}
            textAlign={['center', 'left']}
          >
            모든 테마를 {displayPrice}원에
          </Txt>
          <Spacing size={16} />
          <Txt
            as="p"
            fontSize={[14, 18]}
            fontWeight="light"
            color="#343a40"
            lineHeight={['22px', '33px']}
            textAlign={['center', 'left']}
          >
            어떤 테마든 상관없이 {displayPrice}원에 선택할 수 있어요.
            <br />
            예식이 끝난 일주일 후 만료됩니다.
          </Txt>
        </Left>
        <Right>
          <Img
            src="https://assets.florencecard.me/images/ic-money-main@3x.png"
            srcSet={`https://assets.florencecard.me/images/ic-money-main.png 1x, https://assets.florencecard.me/images/ic-money-main@2x.png 2x, https://assets.florencecard.me/images/ic-money-main@3x.png 3x`}
            alt={`${displayPrice}원`}
          />
        </Right>
      </SectionInner>
    </Section>
  );
}

const Section = styled.section`
  padding: 128px 0 148px 0;
  background-color: #f8f9fa;

  ${onMobile} {
    padding: 40px 0 68px 0;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${onMobile} {
    order: 2;
    align-items: center;
    margin-top: 28px;
  }
`;

const Right = styled.div`
  margin-left: 40px;

  ${onMobile} {
    order: 1;
    margin-left: 0;
  }
`;

const Img = styled.img`
  width: 100%;
  max-height: 240px;

  ${onMobile} {
    width: auto;
    max-height: 135px;
  }
`;
