import { Button, Icon, Spacing, padding, margin, Txt } from '@florencecard/components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { safeScrollTo } from '@florencecard-lib/scroll';
import { colors } from '@florencecard-shared/colors';
import { LetterTheme } from '@florencecard-shared/letter';
import Router from 'next/router';
import React from 'react';
import { useMediaQuery } from '~/core/hooks';
import { letterThemes } from '~/models';
import { onMobile, onMobileMediaQuery } from '~/ui/styles';

const showcaseThemeNames: LetterTheme[] = [
  'whiteflower1',
  'yellowflower1',
  'magazine1',
  'theme4',
  'purple1',
];
const showcaseThemes = letterThemes.filter((theme) => showcaseThemeNames.includes(theme.id));

const hrefStorePage = () => {
  Router.push('/store-list').then(() => {
    safeScrollTo(window, { top: 0 });
  });
};

export default function IntroSection3() {
  const isMobile = useMediaQuery(onMobileMediaQuery);

  return (
    <Section>
      <Txt
        as="h2"
        fontSize={[18, 24]}
        fontWeight="bold"
        lineHeight="30px"
        color={colors.black}
        textAlign="center"
      >
        다양한 테마를 한번에
      </Txt>
      <Spacing size={[16, 14]} />
      <Txt
        as="p"
        fontSize={[14, 18]}
        fontWeight="light"
        lineHeight={['22px', '33px']}
        color="#343a40"
        textAlign="center"
        padding="0 24px"
        wordBreak="keep-all"
      >
        정보를 입력하셨나요?{isMobile ? <br /> : ' '}직접 미리보기로 다양한 테마를 골라보세요.
      </Txt>
      <Spacing size={[16, 14]} />
      <Button size={[20, 28]} onClick={hrefStorePage}>
        <Txt fontSize={[14, 18]} fontWeight="regular" color={colors.primary}>
          모바일 청첩장 보기
        </Txt>
        <Icon
          name="back"
          size={isMobile ? 14 : 18}
          color={colors.primary}
          css={[
            margin.left4,
            css`
              transform: rotate(180deg);
              ${!isMobile ? `margin-top: 2px` : ''};
            `,
          ]}
        />
      </Button>
      <Spacing size={[38, 94]} />
      <Images>
        <ImagesInner>
          {showcaseThemes.map(({ id, name, previewImage }) => (
            <img
              key={id}
              src={previewImage.src}
              srcSet={previewImage.srcset}
              alt={`${name} 템플릿 예시`}
            />
          ))}
        </ImagesInner>
      </Images>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${padding({
    top: 96,
    bottom: 120,
  })};

  ${onMobile} {
    ${padding({
      top: 80,
      bottom: 0,
    })};
  }
`;

const Images = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 500px;

  ${onMobile} {
    height: 400px;
  }
`;

const ImagesInner = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  left: calc(50vw - 547px);
  top: 20px;

  > img {
    border-radius: 20px;
    box-shadow: 0 3px 20px 0 #dee2e6;
    background-color: #d8d8d8;
    margin: 0 10px;
    width: 203px;
  }

  ${onMobile} {
    left: calc(50vw - 400px);

    > img {
      width: 145px;
      margin: 0 7.5px;
    }
  }
`;
