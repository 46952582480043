/** @jsxImportSource @emotion/react */
import { Flex, FlexProps } from '@florencecard-components/flex';
import { gutter, GutterDirection } from '@florencecard-components/gutter';
import React, { ElementType } from 'react';

export type StackProps<Element extends ElementType = 'div'> = FlexProps<Element> & {
  direction?: GutterDirection;
  gutter?: number;
};

export function Stack({ direction = 'vertical', gutter: gutterSpace = 24, ...props }: StackProps) {
  return (
    <Flex
      css={gutter(direction, gutterSpace)}
      flexDirection={direction === 'vertical' ? 'column' : 'row'}
      {...props}
    />
  );
}

type StackWithoutDirectionProps = Omit<StackProps, 'direction'>;

Stack.Horizontal = function StackHorizontal(props: StackWithoutDirectionProps) {
  return <Stack direction="horizontal" {...props} />;
};

Stack.Vertical = function StackVertical(props: StackWithoutDirectionProps) {
  return <Stack direction="vertical" {...props} />;
};
